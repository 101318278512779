import {validOrgnrPattern} from 'common/constants/validation-patterns';
import {Nullable} from 'common/types';
import {TFunction} from 'i18next';
import {getEnv} from 'environment/public.environment';

export class StringUtils {

  static formatOrgNr(orgNr: string, ignoreValidation = false): Nullable<string> {
    if (orgNr && validOrgnrPattern.test(orgNr)) {
      return orgNr.match(/.{3}/g)?.join(' ');
    } else if (ignoreValidation) {
      if (orgNr.length > 2) {
        return orgNr.match(/.{1,3}/g)?.join('\u200c ');
      }
      return orgNr;
    }
    return null;
  }

  static formatFnr(fnr: string): string {
    if (fnr?.length > 5 && fnr?.length <= 11) {
      return fnr.match(/.{1,6}/g)?.join(' ') ?? fnr;
    }
    return fnr;
  }

  static formatRegnr(regNr: string): string {
    const regNrPatternToFormat = /^[a-zA-Z]{2}\d{4,5}$/;
    regNr = regNr?.toUpperCase();

    if (regNr && regNrPatternToFormat.test(regNr)) {
      const regNrArr = regNr.split('');
      regNrArr.splice(2, 0, ' ');
      return regNrArr?.join('');
    }
    return regNr;
  }

  static formatInputValue(inputFieldValue: string, searchType: string, mask?: string): Nullable<string> {
    let formattedValue: Nullable<string> = '';

    if (mask && inputFieldValue) {
      formattedValue = searchType === 'virksomhet'
        ? this.formatOrgNr(inputFieldValue, true)
        : this.formatFnr(inputFieldValue);
      return formattedValue?.toUpperCase();
    }
    return inputFieldValue?.toUpperCase();
  }

  static formatDate(dateTime: Date, appLanguage: string): Nullable<string> {
    if (!dateTime.getDate()) {
      return null;
    }
    const locales = [appLanguage, getEnv().language.default];
    const langSupp = Intl.NumberFormat.supportedLocalesOf(locales, {
      localeMatcher: 'lookup'
    });
    return Intl.DateTimeFormat(langSupp[0], {timeZone: 'Europe/Oslo', year: 'numeric', month: '2-digit', day: '2-digit'}).format(dateTime);
  }

  static formatDateTime(dateTime: Date, appLanguage: string, t: TFunction, hideMidnight?: boolean): Nullable<string> {
    if (!dateTime.getDate() || !dateTime.getTime()) {
      return null;
    }

    const date = StringUtils.formatDate(dateTime, appLanguage);
    const time = Intl.DateTimeFormat(appLanguage,
      {timeZone: 'Europe/Oslo', hour: '2-digit', minute: '2-digit', hourCycle: 'h23'}).format(dateTime);

    if (hideMidnight && time === '00:00') {
      return date;
    } else {
      return t('common:datetime', {date, time});
    }
  }

  static formatBelop(belop: Nullable<number>, currencyType: string, appLanguage: string): Nullable<string> {
    return belop ? Intl.NumberFormat(appLanguage, {
      style: 'currency',
      currency: currencyType,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: 'code'
    }).format(belop) : null;
  }

  static capitalize(s?: string, shouldLowerRest?: boolean) {
    if (s?.length) {
      return shouldLowerRest ? s[0].toUpperCase() + s.slice(1).toLowerCase() : s[0].toUpperCase() + s.slice(1);
    }
    return '';
  }

  static isNumber(value: string): boolean {
    return !isNaN(+value) && !isNaN(parseFloat(value));
  }

  static simpleHash(input: string): string {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + char;
    }
    return `${Math.abs(hash)}`;
  }
}
