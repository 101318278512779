// Colors from Fargepalett 3.0 https://confluence.brreg.no/display/BRD/Fargesystem+3.0#Fargesystem3.0-Fargene
export const colorPalette = {
  grey05: '#ebf2f3',
  grey10: '#d7e5e7',
  grey20: '#b9cacb',
  grey30: '#9faeaf',
  grey60: '#576061',
  grey70: '#414849',
  grey80: '#2c3132',
  grey90: '#191c1c',
  pureGrey20: '#c6c6c6',
  pureGrey80: '#303030',
  mainBlue05: '#e9f1ff',
  mainBlue20: '#9ecaff',
  mainBlue70: '#004785',
  mainBlue80: '#00315e',
  secondaryBlue05: '#e5f3f9',
  secondaryBlue10: '#c9e7f4',
  secondaryBlue80: '#133349',
  yellow025: '#fff7e5',
  yellow20: '#ecc238',
  purple70: '#6819a1',
  green40: '#2ba63c',
  red05: '#ffeced',
  red20: '#fab5b5',
  red60: '#b32728',
  red70: '#8c1718',
  red90: '#400202',
  orange10: '#ffdcc2',
  orange40: '#d77900',
  orange50: '#b36000',
  orange60: '#944b00',
  orange80: '#522300',
};
