import {StringUtils} from 'common/utils/string-utils';

export class ScreenReaderUtils {

  static orgnr(unformattedOrgnr: string): string {
    return unformattedOrgnr.match(/.{1,3}/g)?.join('\u200c ') ?? unformattedOrgnr;
  }

  static fnr(unformattedFnr: string): string {
    return unformattedFnr.match(/.{1,6}/g)?.join('\u200c ') ?? unformattedFnr;
  }

  static setScreenreaderText(value: string): string {
    if (StringUtils.isNumber(value)) {
      return value.length === 9 ? ScreenReaderUtils.orgnr(value) : ScreenReaderUtils.fnr(value);
    }
    return value;
  };
}
