'use client';
import {createUseStyles} from 'react-jss';
import {ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactElement} from 'react';
import {colorPalette} from 'styles/colors';
import {Icon, IconBase} from 'common/components/icon';

const useStyles = createUseStyles((theme) => ({
  base: {
    display: 'inline-block',
    position: 'relative',
    borderRadius: theme.borderRadius,
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '8px 15px 8px',
    margin: '0.5em 1em 0.5em 0',
    fontWeight: 600,
    lineHeight: '1.5em',
    height: 'max-content',
    cursor: 'pointer',

    '@media print': {
      display: 'none !important'
    },
    '&:not(:disabled)': {
      '&:hover': {
        backgroundColor: theme.colors.hover,
        borderColor: `transparent transparent ${theme.colors.secondary}`,
        borderBottomWidth: '3px',
        boxShadow: theme.shadow.btnHover,
        color: theme.colors.secondary,
        paddingBottom: '6px',

        '&[aria-busy=true]': {
          backgroundColor: 'white',
          boxShadow: 'none',
          color: colorPalette.grey30,
          borderColor: colorPalette.grey30,
        },
      },
      '&:focus, &:focus-visible': {
        backgroundColor: theme.colors.focus,
        borderColor: theme.colors.secondary,
        borderWidth: '3px',
        boxShadow: theme.shadow.btnFocus,
        color: theme.colors.secondary,
        padding: '6px 13px',
        outline: 'none',
      },
      '&:active, &:focus:active': {
        backgroundColor: theme.colors.active,
        borderColor: theme.colors.secondary,
        borderWidth: '3px',
        boxShadow: theme.shadow.btnActive,
        color: theme.colors.secondary,
        padding: '6px 13px',
      },
    },
    '&:disabled': {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: colorPalette.grey30,
      cursor: 'default',
    },
  },
  rightAligned: {
    marginLeft: '0.5em',
    marginRight: 0
  },
  primary: {
    backgroundColor: theme.colors.primary,
    borderColor: 'transparent',
    color: 'white',
    boxShadow: theme.shadow.btnPrimary,
  },
  secondary: {
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    color: theme.colors.primary
  },
  simple: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: theme.colors.primary
  },
  iconBefore: {
    marginRight: '0.5em',
  },
  iconAfter: {
    marginLeft: '0.5em',
  }
}), {name: 'Button'});

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'simple';
  icon?: IconBase | ReactElement;
  iconAfter?: boolean;
  rightAlign?: boolean;
  classes?: string;
}
export const Button: FC<ButtonProps> = ({variant, icon, iconAfter, rightAlign, classes, children, disabled, ...buttonProps}) => {
  const styles = useStyles();
  return (
    <button {...buttonProps}
            disabled={disabled}
            aria-disabled={disabled}
            className={`${styles.base} ${styles[variant]} ${rightAlign ? styles.rightAligned : ''} ${classes}`}>
      {!iconAfter && (icon && <Icon icon={icon} className={styles.iconBefore}/>)}
      {children}
      {iconAfter && (icon && <Icon icon={icon} className={styles.iconAfter}/>)}
    </button>
  );
};
