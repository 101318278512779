'use client';

import {getEnv} from 'environment/public.environment';
import {useParams} from 'next/navigation';

export const useLang = () => {
  const lang = useParams<{lang: string}>()?.lang;

  if (lang && getEnv().language.supported.includes(lang)) {
    return lang;
  }

  return getEnv().language.default;
};
