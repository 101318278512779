import {Nullable} from 'common/types';

export const notNullOrEmpty = (v?: Nullable<string>) => !!(v?.length);
export const isDefined = <T>(o: T | undefined): o is T => o !== undefined;


export const isClient = !!(typeof window != 'undefined' && window.document);
export const isServer = !isClient;

export const removeUndefineds = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === 'object') ? removeUndefineds(v) : v)
      .filter(v => !(v == null)) as T;
  } else {
    return Object.entries(obj as Record<string, unknown>)
      .map(([k, v]): [string, unknown] => [k, v && typeof v === 'object' ? removeUndefineds(v) : v])
      .reduce((a: Record<string, unknown>, [k, v]: [string, unknown]) => (v == null ? a : (a[k] = v, a)), {}) as T;
  }
};


type Grouped<T extends Record<string, any>, K extends keyof T> = Record<T[K], T[]>;

export const groupBy = <T extends Record<string, any>, K extends keyof T>(arr: T[], key: K): Grouped<T, typeof key> => {
  return arr.reduce((grouped: Partial<Grouped<T, typeof key>>, el: T) => {
    const groupKey = el[key];
    if (!grouped[groupKey]) {
      grouped[groupKey] = [];
    }

    grouped[groupKey]?.push(el);
    return grouped;
  }, {}) as Grouped<T, typeof key>;
};
