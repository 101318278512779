import {isClient} from 'common/utils';
import {processEnv} from 'environment';

export interface PublicEnv {
  language: {
    default: string;
    supported: string[];
  };
  idleTimer: {
    timeoutMs: number;
    timeoutPromptMs: number;
    throttleMs: number;
  };
  api: {
    enhet: string;
    fellesDatakatalog: string;
  };
  links: {
    brreg: {
      omHeftelserMotorvogn: string;
      omHeftelserPerson: string;
      omEktepakt: string;
      driftsMeldinger: string;
      virksomhetsopplysninger: string;
      forsiden: string;
      minside: string;
      kontaktOss: Record<string, string>;
    };
    statensVegvesen: {
      merOmKjoretoy: Record<string, string>;
    };
  };
  apm: {
    enabled: boolean;
    rum: {
      enabled: boolean;
    };
    serverUrl: string;
    serviceName: string;
    environment: string;
  };
  scf: string;
  personTempKeyApi: {
    url: string;
  };
  internalPersonTempKeyApi: {
    url: string;
  };
}

let clientEnv: PublicEnv;

/**
 * As process.env is not available client-side, environment variables must be passed to the client from the server.
 * setClientEnv is used when recieving the env from the server, to make it available through the same getEnv()-function as on the server
 */
export const setClientEnv = (env: PublicEnv) => {
  clientEnv = env;
};

/**
 * Public environment is available in both server and client. No sensitive values should be stored here.
 *
 * For an environment variable to be available through publicRuntimeConfig,
 * it should be prefixed with `APP_PUBLIC__`.
 *
 * Remember to add new environment variables to both .env.development and .env.test
 */
export const getEnv = (): PublicEnv => {
  if (isClient && process.env.NODE_ENV !== 'test') {
    return clientEnv;
  }

  return {
    language: {
      default: processEnv('APP_PUBLIC__DEFAULT_LANG'),
      supported: processEnv('APP_PUBLIC__SUPPORTED_LANGS')?.split(','),
    },
    idleTimer: {
      timeoutMs: parseFloat(processEnv('APP_PUBLIC__IDLETIMER_TIMEOUT_MS')),
      timeoutPromptMs: parseFloat(processEnv('APP_PUBLIC__IDLETIMER_TIMEOUT_PROMPT_MS')),
      throttleMs: parseFloat(processEnv('APP_PUBLIC__IDLETIMER_THROTTLE_MS')),
    },
    api: {
      enhet: processEnv('APP_PUBLIC__API_ENHET'),
      fellesDatakatalog: processEnv('APP_PUBLIC__API_FELLES_DATAKATALOG')
    },
    links: {
      brreg: {
        omHeftelserMotorvogn: processEnv('APP_PUBLIC__OM_HEFTELSER_MOTORVOGN'),
        omHeftelserPerson: processEnv('APP_PUBLIC__OM_HEFTELSER_PERSON'),
        omEktepakt: processEnv('APP_PUBLIC__OM_EKTEPAKT'),
        driftsMeldinger: processEnv('APP_PUBLIC__DRIFTSMELDINGER'),
        virksomhetsopplysninger: processEnv('APP_PUBLIC__VIRKSOMHETSOPPLYSNINGER'),
        forsiden: processEnv('APP_PUBLIC__FORSIDEN'),
        minside: processEnv('APP_PUBLIC__GUI_MINSIDE'),
        kontaktOss: {
          nb: processEnv('APP_PUBLIC__KONTAKT_OSS_NB'),
          en: processEnv('APP_PUBLIC__KONTAKT_OSS_EN'),
          nn: processEnv('APP_PUBLIC__KONTAKT_OSS_NN'),
        }
      },
      statensVegvesen: {
        merOmKjoretoy: {
          nb: processEnv('APP_PUBLIC__SV_OM_KJORETOY_URL_NB'),
          en: processEnv('APP_PUBLIC__SV_OM_KJORETOY_URL_EN'),
          nn: processEnv('APP_PUBLIC__SV_OM_KJORETOY_URL_NN'),
        }
      }
    },
    apm: {
      enabled: (processEnv('APP_PUBLIC__APM_ENABLED', 'false') === 'true'),
      rum: {
        enabled: (processEnv('APP_PUBLIC__APM_ENABLED', 'false') === 'true')
          && (processEnv('APP_PUBLIC__APM_RUM_ENABLED', 'false') === 'true')
      },
      serverUrl: processEnv('ELASTIC_APM_SERVER_URL', ''),
      serviceName: processEnv('ELASTIC_APM_SERVICE_NAME', ''),
      environment: processEnv('ELASTIC_APM_ENVIRONMENT', ''),
    },
    scf: processEnv('APP_PUBLIC__SCF'),
    personTempKeyApi: {
      url: processEnv('APP_PUBLIC__PERSON_TEMP_KEY_API_URL'),
    },
    internalPersonTempKeyApi: {
      url: processEnv('APP_PUBLIC__INTERNAL_PERSON_TEMP_KEY_API_URL'),
    }
  };
};
