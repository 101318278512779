import {PHASE_PRODUCTION_BUILD} from 'next/constants';

export const processEnv = (varName: string, defaultValue?: string): string => {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return '';
  }
  const val = process.env[varName] ?? defaultValue;
  if (val === undefined || val === null) {
    throw new Error(`Missing required environment variable "${varName}"`);
  }
  return val;
};
